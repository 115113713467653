import React, { useState } from 'react';

const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    const video = document.getElementById('homeVideo');
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
  };

  return (
    <div className="home bg-white text-black min-h-screen flex flex-col">
      <section className="relative w-full h-screen mb-16">
        <img 
          src="/assets/bandimage1.jpg" 
          alt="Band performing on stage" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Welcome To The Dojo</h1>
        </div>
      </section>

      <section className="max-w-4xl mx-auto px-4 mb-16">
        <h2 className="text-3xl font-bold mb-8 text-red-600">About Us</h2>
        <p className="text-lg mb-4">
          Welcome to the world of Dojo Union, where the blues meet the folk, and a touch of country finds its home. We're four friends—Andreas, Sam, Eric, and Ryan—who stumbled upon this musical journey during our college days.
        </p>
        <p className="text-lg mb-4">
          Inspired by the sounds of The Lumineers, Mt. Joy, and Zach Bryan, we've begun crafting our own tunes, pouring our hearts into lyrics that speak to the nomadic spirit within us all.
        </p>
        <p className="text-lg">
          So, whether you're here because you've seen us live or just stumbled upon our site, we hope our songs resonate with you. Join us on this adventure—who knows where it might lead? After all, we're Dojo Union, and we're just getting started.
        </p>
      </section>

      <section className="max-w-4xl mx-auto px-4 mb-16">
        <h2 className="text-3xl font-bold mb-8 text-red-600">Latest Video</h2>
        <div className="relative aspect-w-16 aspect-h-9">
          <video
            id="homeVideo"
            src="/assets/nomadvideo.mp4"
            className="w-full h-full object-cover"
          ></video>
          <button
            onClick={togglePlay}
            className="absolute inset-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 hover:bg-opacity-30"
          >
            <svg
              className={`w-16 h-16 ${isPlaying ? 'hidden' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </section>

      <section className="flex justify-center space-x-8 mb-16 mt-auto">
        <a href="https://www.instagram.com/dojounion/" target="_blank" rel="noopener noreferrer" className="text-black hover:text-red-600 transition-colors duration-300">
          <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
        </a>
        <a href="https://www.tiktok.com/@dojo.union" target="_blank" rel="noopener noreferrer" className="text-black hover:text-red-600 transition-colors duration-300">
          <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/>
          </svg>
        </a>
        <a href="https://www.youtube.com/@DojoUnion" target="_blank" rel="noopener noreferrer" className="text-black hover:text-red-600 transition-colors duration-300">
          <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
          </svg>
        </a>
      </section>
    </div>
  );
};

export default Home;
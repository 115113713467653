import React from 'react';

const Shows = () => {
  const shows = [];  // Empty array for now

  return (
    <div className="shows p-8 bg-black text-white flex flex-col items-center min-h-screen">
      <h1 className="text-4xl font-bold mb-12 text-red-600">Upcoming Shows</h1>
      {shows.length > 0 ? (
        <div className="shows-list w-full max-w-4xl flex flex-col gap-6">
          {shows.map((show, index) => (
            <div key={index} className="show-item p-6 bg-white bg-opacity-10 shadow-lg rounded-lg w-full hover:bg-opacity-20 transition-all duration-300">
              <h2 className="text-2xl font-semibold text-red-600">{show.date}</h2>
              <p className="text-white text-lg mt-2">{show.venue}</p>
              <p className="text-gray-400">{show.location}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-2xl text-red-600 mt-12 animate-pulse">Shows coming soon!</div>
      )}
    </div>
  );
};

export default Shows;
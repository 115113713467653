import React, { useState, useRef, useEffect, useCallback } from 'react';

const Media = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const videos = [
    "/assets/video5.mp4",
    "/assets/video2.mp4",
    "/assets/video3.mp4",
    "/assets/video4.mp4",
	"/assets/video1.mp4"
  ];

  const [enlargedPhoto, setEnlargedPhoto] = useState(null);
  const photos = ["/assets/bandimage1.jpg", "/assets/bandimage2.jpg", "/assets/bandimage3.jpg"];

  const togglePhotoSize = (index) => {
    setEnlargedPhoto(enlargedPhoto === index ? null : index);
  };

  const handleKeyDown = useCallback((e) => {
    if (enlargedPhoto !== null) {
      if (e.key === 'Escape') {
        setEnlargedPhoto(null);
      } else if (e.key === 'ArrowRight') {
        setEnlargedPhoto((prev) => (prev + 1) % photos.length);
      } else if (e.key === 'ArrowLeft') {
        setEnlargedPhoto((prev) => (prev - 1 + photos.length) % photos.length);
      }
    }
  }, [enlargedPhoto, photos.length]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setEnlargedPhoto(null);
    }
  };

  const handleArrowClick = (direction) => {
    if (direction === 'next') {
      setEnlargedPhoto((prev) => (prev + 1) % photos.length);
    } else {
      setEnlargedPhoto((prev) => (prev - 1 + photos.length) % photos.length);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying, currentVideoIndex]);

  const handleVideoChange = (direction) => {
    setIsPlaying(false);
    setCurrentVideoIndex((prevIndex) => {
      if (direction === 'next') {
        return prevIndex === videos.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? videos.length - 1 : prevIndex - 1;
      }
    });
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="media p-8 bg-black text-white flex flex-col items-center">
      <div className="video-carousel relative mb-12 w-full max-w-5xl">
        <div className="video-wrapper relative w-full bg-black aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-2xl">
          {videos.length > 0 ? (
            <div className="relative w-full h-0 pb-[56.25%]">
              <video
                ref={videoRef}
                src={videos[currentVideoIndex]}
                className="absolute top-0 left-0 w-full h-full object-contain"
                key={currentVideoIndex} // Add this line to force re-render when video changes
              ></video>
              <div 
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={togglePlay}
              >
                {!isPlaying && (
                  <button
                    className="bg-red-600 text-white rounded-full p-4 hover:bg-red-700 transition-colors duration-300"
                  >
                    <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-800">
              <p className="text-xl text-gray-400">No videos available</p>
            </div>
          )}
        </div>
        {videos.length > 1 && (
          <div className="absolute top-1/2 left-0 right-0 flex justify-between items-center px-4 transform -translate-y-1/2">
            <button onClick={() => handleVideoChange('prev')} className="bg-red-600 text-white p-2 rounded-full hover:bg-red-700 transition-colors duration-300">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button onClick={() => handleVideoChange('next')} className="bg-red-600 text-white p-2 rounded-full hover:bg-red-700 transition-colors duration-300">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className="photos grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full max-w-5xl">
        {photos.map((src, index) => (
          <div 
            key={index}
            className={`image-container overflow-hidden rounded-lg shadow-lg transition-all duration-300 cursor-pointer
              ${enlargedPhoto === index ? 'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75' : 'hover:scale-105'}`}
            onClick={() => togglePhotoSize(index)}
          >
            <img 
              src={src} 
              alt={`Band ${index + 1}`} 
              className={`transition-all duration-300
                ${enlargedPhoto === index 
                  ? 'w-auto h-auto max-w-[80%] max-h-[80%] object-contain' 
                  : 'w-full h-64 object-cover'}`}
            />
          </div>
        ))}
      </div>

      {enlargedPhoto !== null && (
        <div 
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={handleOutsideClick}
        >
          <img 
            src={photos[enlargedPhoto]} 
            alt={`Enlarged band ${enlargedPhoto + 1}`} 
            className="w-auto h-auto max-w-[80%] max-h-[80%] object-contain"
          />
          <button 
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            onClick={(e) => { e.stopPropagation(); handleArrowClick('prev'); }}
          >
            ←
          </button>
          <button 
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            onClick={(e) => { e.stopPropagation(); handleArrowClick('next'); }}
          >
            →
          </button>
        </div>
      )}
    </div>
  );
};

export default Media;
// components/Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <div className="text-2xl font-bold text-gray-800">
            <Link to="/">Dojo Union</Link>
          </div>
          <div className="space-x-4">
            <Link to="/" className="text-gray-800 hover:text-gray-600">Home</Link>
            <Link to="/media" className="text-gray-800 hover:text-gray-600">Media</Link>
            <Link to="/shows" className="text-gray-800 hover:text-gray-600">Shows</Link>
            <Link to="/contact" className="text-gray-800 hover:text-gray-600">Contact</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;